


import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';
import * as ics from 'ics';
import { EventAttributes } from 'ics';
import IconServiceOutlook from '@/_modules/icons/components/services/icon-service-outlook.vue';
import IconServiceApple from '@/_modules/icons/components/services/icon-service-apple.vue';
import IconAddToCalendar from '@/_modules/icons/components/icon-add-to-calendar.vue';
import FileHelper from '@/_helpers/file.helper';
import { DateTimeFormat } from '@/_types/date-time-format.enum';

const APP_NAME = process.env.VUE_APP_NAME;

export enum CalendarType {
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  OFFICE_365 = 'office365',
  APPLE = 'apple',
  OUTLOOK = 'outlook',
}

@Component({
  name: 'add-to-calendar',
  components: {
    IconServiceOutlook,
    IconServiceApple,
    IconAddToCalendar,
  }
})
export default class AddToCalendar extends Vue {

  @Prop({ type: String, default: '' })
  public readonly title: string;

  @Prop({ type: String, default: '' })
  public readonly location: string;

  @Prop({ type: String, default: '' })
  public readonly details: string;

  @Prop({ type: Date, default: null })
  public readonly start: Date;

  @Prop({ type: Date, default: null })
  public readonly end: Date;

  @Prop({ type: String, default: '' })
  public readonly filename: string;

  @Prop({ type: String, default: 'bottom' })
  public readonly placement: string;

  public readonly CalendarType: typeof CalendarType = CalendarType;

  public getCalendarUrl(calendarType: CalendarType): string {
    let url = this.getBaseUrl(calendarType);
    const params = this.getUrlParams(calendarType);
    if (!url || !params) {
      return null;
    }

    for (const key in params) {
      if (!key || !params[key]) {
        continue;
      }
      url += `&${key}=${encodeURIComponent(params[key]).replace(/%20/g, '+')}`;
    }

    return url;
  }

  public async exportIcs(): Promise<void> {
    const ics = await this.getIcs();
    if (!ics) {
      return;
    }
    const filename = this.filename || (
      this.title
      + ' ('
      + this.$moment(this.start).format(DateTimeFormat.DATE_MEDIUM)
      + ' - '
      + this.$moment(this.end).format(DateTimeFormat.DATE_MEDIUM)
      + ')'
    );
    const fileName = `${filename}.ics`;
    FileHelper.downloadFile(new File([ ics ], fileName), fileName);
  }

  private getUrlParams(calendarType: CalendarType): { [key: string]: string } {

    switch (calendarType) {
      case CalendarType.GOOGLE: {
        return {
          text: this.title,
          details: this.details,
          location: this.location,
          dates: this.getGoogleDateFormat(this.start) + '/' + this.getGoogleDateFormat(this.end),
        };
      }

      case CalendarType.MICROSOFT:
      case CalendarType.OFFICE_365: {
        return {
          startdt: this.getMicrosoftDateFormat(this.start),
          enddt: this.getMicrosoftDateFormat(this.end),
          subject: this.title,
          body: this.details,
          location: this.location,
        };
      }

    }

    return null;
  }

  private getGoogleDateFormat(date: Date): string {
    return this.$moment(date).utc(false).format('YYYYMMDDTHHmmss') + 'Z';
  }

  private getMicrosoftDateFormat(date: Date): string {
    return this.$moment(date).utc(false).format('YYYY-MM-DDTHH:mm:ss') + 'Z';
  }

  private getBaseUrl(calendarType: CalendarType): string {
    switch (calendarType) {
      case CalendarType.GOOGLE:
        return 'http://www.google.com/calendar/render?action=TEMPLATE&trp=false';

      case CalendarType.MICROSOFT:
        return 'https://outlook.live.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent';

      case CalendarType.OFFICE_365:
        return 'https://outlook.office.com/calendar/0/deeplink/compose?path=%2Fcalendar%2Faction%2Fcompose&rru=addevent';
    }

    return null;
  }

  private async getIcs(): Promise<string> {
    const startMoment = this.$moment(this.start);
    const endMoment = this.$moment(this.end);
    const eventAttributes: EventAttributes = {
      productId: APP_NAME,
      start: [
        startMoment.year(),
        startMoment.month() + 1,
        startMoment.date(),
        startMoment.hours(),
        startMoment.minutes()
      ],
      end: [
        endMoment.year(),
        endMoment.month() + 1,
        endMoment.date(),
        endMoment.hours(),
        endMoment.minutes()
      ],
      startInputType: 'local',
      startOutputType: 'utc',
      endInputType: 'local',
      endOutputType: 'utc',
      title: this.title,
      description: this.details,
      location: this.location,
    };
    const result = await ics.createEvent(eventAttributes);
    return (!!result.error || !result.value) ? null : result.value;
  }

}
