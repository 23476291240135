<template>
  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.4247 0.766602H3.15466C2.47165 0.767647 1.81692 1.03935 1.33395 1.52243C0.850859 2.00535 0.579151 2.66012 0.578125 3.34314V18.4901C0.579171 19.1731 0.850872 19.8278 1.33395 20.3108C1.81687 20.7939 2.47165 21.0656 3.15466 21.0666H21.4247C22.1077 21.0656 22.7624 20.7939 23.2454 20.3108C23.7285 19.8279 24.0002 19.1731 24.0012 18.4901V3.34314C24.0002 2.66012 23.7285 2.00539 23.2454 1.52243C22.7625 1.03934 22.1077 0.767628 21.4247 0.766602ZM22.4397 18.4901C22.4397 18.7593 22.3327 19.0174 22.1423 19.2077C21.952 19.3981 21.6939 19.5051 21.4247 19.5051H3.15466C2.8854 19.5051 2.6273 19.3981 2.43698 19.2077C2.24666 19.0174 2.13965 18.7593 2.13965 18.4901V7.01276H22.4397L22.4397 18.4901ZM22.4397 5.45122H2.13966V3.34314C2.13966 3.07388 2.24667 2.81579 2.43698 2.62546C2.6273 2.43515 2.88541 2.32814 3.15467 2.32814H21.4247C21.6939 2.32814 21.952 2.43514 22.1424 2.62546C22.3327 2.81577 22.4397 3.07389 22.4397 3.34314L22.4397 5.45122Z" fill="#02ACEA"/>
    <path d="M5.26271 3.88966C5.26271 4.32082 4.91311 4.67043 4.48194 4.67043C4.05077 4.67043 3.70117 4.32082 3.70117 3.88966C3.70117 3.45849 4.05077 3.10889 4.48194 3.10889C4.91311 3.10889 5.26271 3.45849 5.26271 3.88966Z" fill="#02ACEA"/>
    <path d="M7.60451 3.88966C7.60451 4.32082 7.25491 4.67043 6.82374 4.67043C6.39257 4.67043 6.04297 4.32082 6.04297 3.88966C6.04297 3.45849 6.39257 3.10889 6.82374 3.10889C7.25491 3.10889 7.60451 3.45849 7.60451 3.88966Z" fill="#02ACEA"/>
    <path d="M9.94826 3.88966C9.94826 4.32082 9.59866 4.67043 9.16749 4.67043C8.73632 4.67043 8.38672 4.32082 8.38672 3.88966C8.38672 3.45849 8.73632 3.10889 9.16749 3.10889C9.59866 3.10889 9.94826 3.45849 9.94826 3.88966Z" fill="#02ACEA"/>
    <path d="M9.89363 17.2581C10.1143 17.3803 10.3635 17.4413 10.6155 17.4348C10.8677 17.4282 11.1134 17.3542 11.3273 17.2207L15.7496 14.4568L15.7494 14.4566C16.1624 14.1983 16.4132 13.7455 16.4132 13.2586C16.4132 12.7715 16.1624 12.3187 15.7494 12.0604L11.3271 9.29724H11.3273C11.0407 9.11826 10.6997 9.04785 10.3657 9.09856C10.0318 9.14945 9.72721 9.31833 9.50708 9.57452C9.28696 9.83071 9.16602 10.1573 9.16602 10.4951V16.0229C9.16602 16.2751 9.23346 16.5226 9.36156 16.7397C9.48948 16.9569 9.67317 17.1358 9.89364 17.2582L9.89363 17.2581ZM10.7275 10.7603L14.7196 13.2588L10.7275 15.7573V10.7603Z" fill="#02ACEA"/>
  </svg>
</template>
<script>
import Vue from 'vue';

const IconVideoCall = Vue.extend({
  name: 'icon-video-call',
});
export default IconVideoCall;
</script>
